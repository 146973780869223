import React from "react";
import { TodoProvider } from "./context/TodoContext";

import "./assets/styles/css/default.css";
import TodoInput from "./components/TodoInput";
import TodoList from "./components/TodoList";
import Alert from "./components/Alert";

function App() {
	return (
		<TodoProvider>
			<TodoInput />
			<TodoList />
			<Alert title={"Delete Task?"} />
		</TodoProvider>
	);
}

export default App;
