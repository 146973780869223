import React from "react";
import { useTodo } from "../context/TodoContext";

import Todo from "./Todo";
export default function TodoList(): JSX.Element {
	const { todos } = useTodo();

	let length: number;
	try {
		length = todos.length;
	} catch (TypeError) {
		length = 0;
	}

	return (
		<>
			<div className="todo-empty" style={{ display: length > 0 ? "none" : "flex" }}>
				<p className="todo-empty__text">No Tasks</p>
			</div>
			<ul className="todo-list">
				{length > 0 &&
					todos.map((todo, key) => {
						return (
							<Todo
								id={todo.id}
								title={todo.title}
								description={todo.description}
								completed={todo.completed}
								key={key}
							/>
						);
					})}
			</ul>
		</>
	);
}
