import React from "react";
import { useTodo } from "../context/TodoContext";
import "../assets/styles/css/alert.css";

interface alertProps {
	title: string;
}

export default function Alert({ title }: alertProps) {
	const { alertState, setAlertState, deleteTodo, idAlert } = useTodo();

	return (
		<section className="alert" style={{ display: alertState ? "flex" : "none" }}>
			<h2 className="alert__title">{title}</h2>
			<div className="alert__buttons">
				<div
					className="alert__button"
					onClick={() => {
						deleteTodo(idAlert!);
						setAlertState!(!alertState);
					}}
				>
					Yes
				</div>
				<div
					className="alert__button"
					onClick={() => {
						setAlertState!(!alertState);
					}}
				>
					No
				</div>
			</div>
		</section>
	);
}
