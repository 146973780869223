import React, { useState } from "react";
import { useTodo, ITodo } from "../context/TodoContext";

export default function Todo({ id, title, description, completed }: ITodo) {
	const [actionsState, setActionsState] = useState<boolean>(false);
	const { alertState, setAlertState, setIdAlert, inputEdit, setInputEdit, setIdEdit } = useTodo();
	return (
		<li className="todo-list__item" style={{ display: !completed ? "flex" : "none" }}>
			<div className="todo-list__container">
				<article
					className="todo-list__article"
					onClick={() => {
						setActionsState(!actionsState);
					}}
				>
					<h3 className="todo-list__title">{title}</h3>
					<p className="todo-list__text">{description}</p>
				</article>
				<div
					className="todo-list__delete"
					onClick={() => {
						setIdAlert!(id);
						setAlertState!(!alertState);
					}}
				>
					<img
						src={process.env.PUBLIC_URL + "/assets/icons/delete.svg"}
						alt="delete"
						className="todo-list__delete-image"
					/>
				</div>
			</div>
			<div className="todo-list__actions" style={{ display: actionsState ? "flex" : "none" }}>
				<img
					src={process.env.PUBLIC_URL + "/assets/icons/share.svg"}
					alt="share"
					className={"todo-list__actions-img"}
				/>
				<img
					src={process.env.PUBLIC_URL + "/assets/icons/info.svg"}
					alt="info"
					className={"todo-list__actions-img"}
				/>
				<img
					src={process.env.PUBLIC_URL + "/assets/icons/edit.svg"}
					alt="edit"
					className={"todo-list__actions-img"}
					onClick={() => {
						setIdEdit!(id);
						setInputEdit!(!inputEdit);
						setActionsState(!actionsState);
					}}
				/>
			</div>
		</li>
	);
}
