import React, { useContext, useState, useEffect } from "react";

export interface ITodo {
	id: number;
	title: string;
	description: string;
	completed: boolean;
}
export type TodoContextType = {
	todos: ITodo[];

	createTodo: (todo: ITodo) => void;

	deleteTodo: (id: number) => void;
	editTodo?: (id: number) => void;
	alertState?: boolean;
	setAlertState?: (state: boolean) => void;
	idAlert?: number;
	setIdAlert?: (state: number) => void;
	inputEdit?: boolean;
	setInputEdit?: (state: boolean) => void;
	idEdit?: number;
	setIdEdit?: (state: number) => void;
};

const TodoContext = React.createContext<TodoContextType | null>(null);

export function useTodo() {
	return useContext(TodoContext) as TodoContextType;
}

export function TodoProvider({ children }: { children: React.ReactNode }) {
	const [todos, setTodos] = useState<ITodo[]>([JSON.parse(window.localStorage.getItem("todos") || "[]")]);
	const [alertState, setAlertState] = useState<boolean>(false);
	const [idAlert, setIdAlert] = useState<number>(0);

	const [inputEdit, setInputEdit] = useState<boolean>(false);
	const [idEdit, setIdEdit] = useState<number>(0);

	useEffect(() => {
		let loadTodos = JSON.parse(localStorage.getItem("todos") || "[]");
		setTodos(loadTodos);
	}, []);

	useEffect(() => {
		window.localStorage.setItem("todos", JSON.stringify(todos));
	}, [todos]);

	function createTodo({ id, title, description, completed }: ITodo) {
		if (title !== "") {
			const newTodo: ITodo = {
				id: id,
				title: title,
				description: description,
				completed: completed,
			};

			setTodos((prevTodos) => [newTodo, ...prevTodos]);
		}
	}

	function deleteTodo(id: number) {
		setTodos(todos.filter((todo) => todo.id !== id));
	}
	function editTodo(id: number) {
		setInputEdit(!inputEdit);
		setIdEdit(id);
	}

	const values = {
		todos,
		createTodo,
		deleteTodo,
		alertState,
		setAlertState,
		idAlert,
		setIdAlert,
		editTodo,
		inputEdit,
		idEdit,
		setIdEdit,
		setInputEdit,
	};

	return <TodoContext.Provider value={values}>{children}</TodoContext.Provider>;
}
