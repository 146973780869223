import React, { useRef } from "react";
import { useTodo } from "../context/TodoContext";

import "../assets/styles/css/todo.css";

export default function TodoInput(): JSX.Element {
	const { createTodo, inputEdit, setInputEdit, idEdit, deleteTodo } = useTodo();

	const titleRef = useRef<HTMLInputElement | null>(null);
	const descriptionRef = useRef<HTMLInputElement | null>(null);

	return (
		<section className="todo-inputs">
			<div className="todo-inputs__inputs">
				<input
					type="text"
					className="todo-inputs__input"
					placeholder="Add new todo"
					ref={titleRef}
					required={true}
				/>
				<input
					type="text"
					className="todo-inputs__input"
					placeholder="Specify with description"
					ref={descriptionRef}
				/>
			</div>
			<button
				className="todo-inputs__add-button"
				onClick={() => {
					if (!inputEdit) {
						createTodo({
							title: titleRef.current!.value,
							description: descriptionRef.current!.value,
							completed: false,
							id: new Date().getTime(),
						});
					} else {
						deleteTodo(idEdit!);
						createTodo({
							title: titleRef.current!.value,
							description: descriptionRef.current!.value,
							completed: false,
							id: new Date().getTime(),
						});
						setInputEdit!(!inputEdit);
					}
					titleRef.current!.value = "";
					descriptionRef.current!.value = "";
				}}
			>
				<img
					src={process.env.PUBLIC_URL + "/assets/icons/" + (!inputEdit ? "add" : "edit") + ".svg"}
					alt="add"
					className="todo-inputs__add-image"
				/>
			</button>
		</section>
	);
}
